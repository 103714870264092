<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 occupancy-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="occupancy-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: helpLinkName
        }"
      >
        <template v-slot:left>
          <router-link :to="{ name: backLinkName }">
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("occupancy", "header", "export") }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <ul class="clebex-item-section pill">
      <li
        class="clebex-item-section-item slide-icon-border full-right-underline"
      >
        <button
          @click="getExport('current')"
          class="clebex-item-content-wrapper"
        >
          <dl class="clebex-item-dl">
            <dd class="clebex-item-dd">
              <div>
                {{ displayLabelName("occupancy", "dashboard", "current") }}
              </div>
            </dd>
          </dl>
        </button>
      </li>
      <li
        v-if="backLinkName !== 'r_occupancy-technical-list'"
        class="clebex-item-section-item slide-icon-border full-right-underline"
      >
        <button
          @click="getExport('historic')"
          class="clebex-item-content-wrapper"
        >
          <dl class="clebex-item-dl">
            <dd class="clebex-item-dd">
              <div>
                {{ displayLabelName("occupancy", "dashboard", "historic") }}
              </div>
            </dd>
          </dl>
        </button>
      </li>
      <li
        v-if="backLinkName !== 'r_occupancy-technical-list'"
        class="clebex-item-section-item slide-icon-border full-right-underline"
      >
        <button @click="getExport('usage')" class="clebex-item-content-wrapper">
          <dl class="clebex-item-dl">
            <dd class="clebex-item-dd">
              <div>
                {{ displayLabelName("occupancy", "export-usage", "usage") }}
              </div>
            </dd>
          </dl>
        </button>
      </li>
    </ul>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray"></nav>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "occupancyExport",
  mixins: [helpOnlineMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  data() {
    return {
      helpSlug: "occupancy-data-export"
    };
  },
  created() {
    if (!this.filters || !this.filters.length) {
      this.$router.push({
        name: this.backLinkName
      });
    }
    this.$store.commit("global/setIsFullWidthScreen", true, {
      root: true
    });
  },
  computed: {
    ...mapState("searchFilters", ["filters"])
  },
  methods: {
    ...mapActions("occupancy", ["getExport"])
  },
  props: {
    backLinkName: {
      type: String
    },
    helpLinkName: {
      type: String
    }
  },
  beforeUnmount() {
    this.$store.commit("global/setIsFullWidthScreen", false, {
      root: true
    });
  }
};
</script>
